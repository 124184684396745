import gql from 'graphql-tag'
import {IDS} from "../../Constants/IDS";

export const GET_USER_BY_MOBILE = gql`
    query($id:uuid!,$mobile:String!){
        Users(where:{id:{_neq:$id}, mobile:{_eq:$mobile}}){
            id
        }
    }`

export const GET_USERS_BY_ORGANIZATION = gql`
    query($organizationId:uuid!){
        Users(where:{organizationId:{_eq:$organizationId}}){
            id
            name
            mobile
            reportsTo
            userTypeId            
            Type {id name}
        }
    }`

export const GET_USERS_BY_USERTYPE = gql`
    query getUserByTypeId($userTypeId:[uuid!]){
        Users(where:{userTypeId:{_in:$userTypeId}}){
            id
            name
            mobile
            userCode
        }
    }`

export const GET_PILOTSALESMAN_BY_SALES_OFFICER = gql`
query getPilotSalesmanBySO($salesOfficerId:uuid!){
    pilotSalesmen: Users(where:{reportsTo:{_eq:$salesOfficerId}, userTypeId:{_eq:"${IDS.userTypes.PilotSalesman}"}}){
        id
        name        
        mobile
    }
}`
