import gql from 'graphql-tag'
import {IDS} from "../../Constants/IDS";

export const GET_ROUTE_SHOPS_BY_ID = gql`
    query ($routeId:uuid!){
        RouteShops: RouteShops(where: {routeId: {_eq: $routeId}},order_by:{sequence:asc}){
            id
            organizationId
            lat
            long
            sequence
            status
            Organization{
                id
                name
                mobile
                zone
                Type{
                    id
                    type
                }
            }
        }
    }`

export const GET_ROUTE_SHOPS_BY_ROUTE_ID = gql`
query getRouteShopsByRouteId($routeId:[uuid!]){
    RouteShops(where: {routeId: {_in: $routeId}}, order_by:{sequence:asc}){
        id           
        Organization{
          id
          name
          mobile 
          Users(where:{userTypeId:{_eq:"${IDS.userTypes.Customer}"}}){ id name mobile }
        }            
    }
}`

export const GET_ROUTE_SHOPS = gql`
    query {
        RouteShops(order_by:{sequence:asc}){
            id
            organizationId
            status
        }
    }`

export const GET_ORGANIZATION_ASSIGNED_ROUTES = gql`
query getRoutesByOrgId($orgId:[uuid!]){
    routes:Routes(where: {organizationId: {_in:$orgId}}){
        id
        name            
    }
}`